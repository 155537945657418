import React, {  
    useEffect,
    useReducer, 
    useState  } from 'react';
import Cookies from 'universal-cookie';
import config from '../config';

const UserContext = React.createContext([{}, () => { }]);


const UserProvider = (props) => {
    const cookies = new Cookies();
    const [showVideo, setShowVideo] = useState(false);
    const [developmentMode, setDevelopmentMode] = useState(false);
    const contentReducer = (state, action) => {
        switch (action.type) {
            case 'OTHERS':
                return {
                    isOpen: true,
                    title: action.title,
                    content:action.content,
                    onClose: action.onClose,
                    size: action.size,
                    id : action.id
                }
            default:
                return {
                    isOpen: false,
                    text: '',
                    size: 'lg',
                    content: null,
                    title: '',
                    onClose: null
                }
        }
    }
    const [rightState, rightDispatch] = useReducer(contentReducer, {
        isOpen: false,
        text: '',
        size: 'lg',
        content: null,
        title: '',
        onClose: null
    })
    const [inputHp, setInputHp] = useState('');
    

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);        
        if (myCookies && myCookies.development) {
            setDevelopmentMode(true);
        }
        // eslint-disable-next-line
    }, [])

    

    return (
        <UserContext.Provider value={{
            showVideo: [showVideo, setShowVideo],
            rightState: [rightState, rightDispatch],
            developmentMode : [developmentMode, setDevelopmentMode],
            inputHp : [inputHp, setInputHp],
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider };