import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router";
import api from "../../api";
import config from "../../config";
import CusDetailComp from "../Home/Component/CusDetailComp";
import { useReactToPrint } from 'react-to-print';

export function useMethodPayment(){
    const { isLoading, setIsLoading } = useGlobalFunction();
    const myState = useContext(UserContext);
    const [rightState, rightDispatch] = myState.rightState;
    const [inputHp, setInputHp] = myState.inputHp;
    const [showVideo, setShowVideo] = myState.showVideo;
    const navigate = useNavigate();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [usingVa, setUsingVa] = useState(false);
    const [maxToVa, setMaxToVa] = useState(0);
    const [customerDetail, setCustomerDetail] = useState(null);
    const [paymentId, setPaymentId] = useState(0)
    const componentRef = useRef();
    const [alertValue, setAlertValue] = useState({
        show: false,
        color: 'danger',
        text: '',
    });
    const [isAvailable, setIsAvailable] = useState(true); // untuk konsumen yang nomor hp tidak terdaftar
    // const [paymentType, setPaymentType] = useState('qris')

    const fetchData = useCallback(async(formInput) => {
        try{
            let newForm = new FormData();
            let tmp = {
                'noinvoice': formInput
            }
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.invoiceInfo}`, newForm).then(res => res.data);
            if (res){
                if (res.results.list_pembayaran.length > 0){
                    navigate('/cash-payment')
                }else{
                    if (!res.results.json_data){
                        let tmp = {...res.results}
                        tmp['logo'] = res.logo;
                        tmp['ahass_name'] = res.ahass_name
                        // setVaNumber(res.result.no_va)
                        setCustomerDetail(tmp);
                        rightDispatch({
                            type: 'OTHERS',
                            content : <CusDetailComp
                                customerDetail={res.results}
                            />
                        })
                        setPaymentId(res.results.id);
                        setMaxToVa(res.max_to_va);
                        console.log("using va", parseInt(res.results.total) >= parseInt(res.max_to_va))
                        if (parseInt(res.results.total) >= parseInt(res.max_to_va)){
                            setUsingVa(true);
                        }else{
                            setUsingVa(false);
                        }
                        console.log("using va state", usingVa)
                    }else{
                        navigate('/non-cash-payment')
                    }
                }
            }
        }catch(error){
            const { response } = error;
            setAlertValue(alertValue => ({...alertValue, text: response.data.message}))
            setIsAvailable(false);
            // console.log('response is >>', response.data);
        }
        // eslint-disable-next-line
    },[rightDispatch])

    useEffect(() => {
        if (inputHp === ''){
            navigate('/')
        }else{
            sessionStorage.setItem('inputHp', inputHp)
            fetchData(inputHp)
        }
        // eslint-disable-next-line
    },[fetchData, inputHp])

    useEffect(() => {
        setShowVideo(false)
        // eslint-disable-next-line
    },[])


    const handleCancel = () => {
        if (selectedPaymentMethod == null) {
            setInputHp('')
            navigate('/')
        } else {
            setSelectedPaymentMethod(null)
        }

    }

    const handleNonTunai = async () => {
        setSelectedPaymentMethod('non-tunai')
    }

    const handleTunai = async() => {
        setSelectedPaymentMethod('tunai')
        sessionStorage.setItem('customerDetail', JSON.stringify(customerDetail))
        navigate('/cash-payment')
    }

    const handleEDCBCA = async() => {
        sessionStorage.setItem('customerDetail', JSON.stringify(customerDetail))
        sessionStorage.setItem('inputHp', inputHp)
        navigate('/edc-bca-payment')
    }

    const handleNonEDC = async() => {
        sessionStorage.setItem('customerDetail', JSON.stringify(customerDetail))
        sessionStorage.setItem('inputHp', inputHp)
        try{
            // selalu kebentuk virtual account walau udah di setting, mungkin state usingVa ini di panggil / terset di fungsi lain
            // jadi saya lakukan pengechekan kembali, mohon di hapus jika salah
            if (!usingVa){
                let newForm = new FormData();
                let tmp = {
                    'payment_id': customerDetail.id
                }
                newForm.append('formData', JSON.stringify(tmp))
                const res = await api.post(`${config.endPoint.createPayment}`, newForm).then(res => res.data);
                console.log('res submit non tunai >>>>>', res);
            }
            navigate('/non-cash-payment')
        } catch(error){
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const printThePage = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({ ...alertValue, show: false }))
    }

    return {
        isLoading, showVideo, selectedPaymentMethod, usingVa, paymentId, customerDetail, handleCancel, handleNonTunai, handleTunai, handleEDCBCA, handleNonEDC,
        rightDispatch, printThePage, rightState, setIsLoading, componentRef,
        alertValue, handleCloseAlert, isAvailable
    }
}