// eslint-disable-next-line
export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
      // development: "http://127.0.0.1:8000",
        development: "https://be-vending.asper-honda.com",
        production: "https://vendingmachine-be.myappscenter.com", 
        // wsServer : "ws://127.0.0.1:8000/ws/",
        wsServer : "wss://vendingmachine-be.myappscenter.com/ws/",
        video: "https://vendingmachinenode.myappscenter.com", 
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        // wsServer : "ws://127.0.0.1:8000/ws/",
        // video: "http://localhost:4000", 
    },
    localStorageKey : {      
      USER: "__selfPaymenttUser",
      ACCESS_TOKEN: "__selfPaymenttToken",      
      PERUSAHAAN : '_selfPaymenttperusahaanInfo',
    },
    cookiesName : 'fww_client',
    expiresSession : 8640000,
    itemPerPage : 10,
    timeOutValue : 10000,
    endPoint : {
      // auth
      createPayment : '/payment/api/v1/payment/',
      getPayment : '/payment/api/v1/payment/',
      video : `/payment/api/v1/video/`,
      login : `/auth/login-outlet/`,
      invoiceInfo : `/payment/api/v1/get-invoice-info/`,
      masterVa : `/payment/api/v1/master-va/`,
      createVa : `/payment/api/v1/create-va/`,
      cancelPayment : `/payment/api/v1/cancel-payment/`,
      openMachine : `/payment/api/v1/open-machine/`,

      // pinScreen 
      pinScreen : `/payment/api/v1/pin-screen/`,
      loginPinScreen : `/payment/api/v1/login-pin-screen/`,
      paymentGroup : `/payment/api/v1/payment-group/`,
    }
}
