import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import api from "../../api";
import config from "../../config";


export function useVideoScreen(){
    const [videoLoop, setVideoLoop] = useState([]);
    const videoRef = useRef();
    const myState = useContext(UserContext);
    const [showVideo] = myState.showVideo;
    const [developmentMode] = myState.developmentMode;
    
    const [videoIndex, setVidIndex] = useState(0)
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();

    const handleFetchVideo = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.video}`).then(res => res.data);
            let tmp = []
            res.results.map(post => tmp.push(post.id_display))
            setVideoLoop(tmp);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';
            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }, [setAlertValue, setIsLoading])

    useEffect(() => {
        handleFetchVideo();

    }, [handleFetchVideo])

    const handleNextVideo = () => {
        if (videoIndex === (videoLoop.length - 1)) {
            setVidIndex(0)
        } else {
            setVidIndex(videoIndex + 1)
        }
        if (videoRef.current) {
            setTimeout(() => {
                videoRef.current.play()                
            }, 200)
        }
    }

    return {
        videoLoop, handleFetchVideo, videoRef, showVideo, handleNextVideo, videoIndex, isLoading, developmentMode,
        alertValue, 
    }
}