import config from "../../config";
import { useVideoScreen } from "./videoFunction";

const VideoScreen = () => {
    const { 
        showVideo, videoLoop, videoRef, handleNextVideo, videoIndex, developmentMode,
    } = useVideoScreen();
    
    return(
        <div className={`${!showVideo ? 'hidden' : 'flex flex-row justify-center items-center h-screen'}`}>
            {videoLoop && videoLoop[videoIndex] && videoLoop[videoIndex] !== 'undefined' &&
                <video
                    ref={videoRef}
                    // ref={el => {videoRef.current = el}} 
                    // src={videoLoop[videoIndex]}
                    src={`${config.apiBaseUrl.video}/video/?tagId=${videoLoop[videoIndex]}`}
                    autoPlay
                    // muted
                    onEnded={handleNextVideo}
                    // className={`w-auto min-w-full min-h-full max-w-none overflow-hidden object-contain videoInsert`}                  
                    className={`overflow-hidden w-full  aspect-auto`}
                    controls={developmentMode ? true : false}
                />
            }
        </div>
    )
}
export default VideoScreen;